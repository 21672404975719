import { useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { GET_GATES_DATA } from '../queries/Queries';

import TransitionEffect from '../lib/TransitionEffect';
import SEOHelmet from '../components/seoHelmet/SEOHelmet';
import HeroSection from '../components/heroSection/HeroSection';
import DoorsWithCatalog from '../components/doorsWithCatalog/DoorsWithCatalog';
import Loader from '../components/loader/Loader';
import ErrorMessage from '../components/errorMessage/ErrorMessage';

export default function GatesPage({ initialLoading, onLoaded, toggleModalFormVisibility }) {
	const { loading, error, data } = useQuery(GET_GATES_DATA);

	const seoGates = data && data?.allSeoGates?.edges[0]?.node?.seoGates;
	const heroSection = data && data?.allGatesHero?.edges[0]?.node?.gatesHero;
	const content = data && data?.allGatesContent?.edges?.map((item) => item?.node?.gatesContent?.text)?.reverse();
	const catalogs = data && data?.allGatesCatalogs?.edges?.map((item) => item?.node?.gatesCatalogs)?.reverse();

	// eslint-disable-next-line
	useEffect(() => setTimeout(onLoaded, 2500), []);

	return (
		<>
			{loading && !initialLoading ? <Loader /> : null}
			{error && <ErrorMessage />}
			{!(loading && error) && (
				<TransitionEffect loading={loading}>
					<SEOHelmet seoData={seoGates} />
					<HeroSection heroSection={heroSection} />
					<DoorsWithCatalog
						content={content}
						catalogs={catalogs}
						toggleModalFormVisibility={toggleModalFormVisibility}
					/>
				</TransitionEffect>
			)}
		</>
	);
}
